import PageLinkComponent from '@/components/page/linkComponent';
import { alphaButtonBoxShadow } from '@/providers/theme/themes';
import { ArrowRightAltRounded as ArrowRightAltRoundedIcon } from '@mui/icons-material';
import { Box, BoxProps, CircularProgress, SxProps, Typography } from '@mui/material';
import { ReactNode } from 'react';

export type styledCustomButtonProps = BoxProps & {
	children: ReactNode,
	variant?: 'contained' | 'outlined' | 'text',
	href?: string,
	target?: string,
	color?: string,
	endIcon?: ReactNode,
	onClick?: () => void,
	endIconColor?: string,
	size?: 'small' | 'medium' | 'large',
	borderRadius?: number | string,
	labelStyles?: SxProps,
	loading?: boolean,
	sx?: SxProps
};

export default function StyledCustomButton( {
	children,
	variant = 'contained',
	href,
	target,
	color,
	endIcon,
	onClick,
	size = 'large',
	borderRadius = '10px',
	endIconColor,
	labelStyles,
	loading,
	...props
}: styledCustomButtonProps ) {
	
	const backgroundColorMap = {
		primary  : 'primary.main',
		secondary: 'secondary.main',
		warning  : 'warning.main',
		error    : 'error.main',
		success  : 'success.main',
		info     : 'info.main',
		white    : '#ffffff',
	};
	const buttonSize = size === 'small' ? 0.5 : size === 'large' ? 1 : 1;
	
	function returnColor( bg: boolean, label: boolean, border: boolean ) {
		if ( bg ) {
			if ( variant === 'contained' ) {
				return backgroundColorMap[ color ] || color || '#000000';
			} else if ( variant === 'outlined' ) {
				return 'transparent';
			} else if ( variant === 'text' ) {
				return 'transparent';
			} else {
				return backgroundColorMap[ color ] || color || '#000000';
			}
		}
		
		if ( label ) {
			if ( variant === 'contained' ) {
				if ( color === 'white' ) return '#000000';
				return '#ffffff';
			} else if ( variant === 'outlined' ) {
				return backgroundColorMap[ color ] || color || '#000000';
			} else {
				return backgroundColorMap[ color ] || color || '#000000';
			}
		}
		
		if ( border ) {
			if ( variant === 'contained' ) {
				return backgroundColorMap[ color ] || color || '#000000';
			} else if ( variant === 'outlined' ) {
				return backgroundColorMap[ color ] || color || '#000000';
			} else {
				return backgroundColorMap[ color ] || color || '#000000';
			}
		}
	}
	
	if ( loading ) {
		return (
			<Box
				sx={{
					bgcolor       : 'divider',
					display       : 'flex',
					alignItems    : 'center',
					justifyContent: 'center',
					px            : variant === 'text' ? 0 : 3,
					py            : buttonSize,
					borderRadius  : borderRadius,
				}}>
				<CircularProgress size={15}/>
			</Box>
		);
	}
	
	return (
		<Box
			{...props}
			sx={{
				'backgroundImage'   : 'linear-gradient(#ffffff 0 0)',
				'backgroundSize'    : '200% .08em',
				'backgroundPosition': '200% 100%',
				'backgroundRepeat'  : 'no-repeat',
				'transition'        : 'background-size .3s, background-position .3s .3s',
				'borderRadius'      : borderRadius,
				'px'                : variant === 'text' ? 0 : 3,
				'py'                : buttonSize,
				'bgcolor'           : returnColor( true, false, false ),
				'border'            : color !== 'white' && 1,
				'borderColor'       : returnColor( false, false, true ),
				'boxShadow'         : color === 'white' ? alphaButtonBoxShadow : 'unset',
				'width'             : 'max-content',
				'.buttonText'       : {
					color     : returnColor( false, true, false ),
					fontWeight: '500',
					fontSize  : 16,
					...labelStyles,
				},
				':hover': {
					'cursor'    : 'pointer',
					'transition': color === 'white' ? 'all .3s' : variant === 'text'
						? 'all .5s'
						: 'background-size .3s .3s, background-position .3s',
					'backgroundSize'    : color !== 'white' && '100% 100%',
					'backgroundPosition': color !== 'white' && '100% 100%',
					'borderColor'       : variant === 'text' && ( backgroundColorMap[ color ] || color || '#000000' ),
					'bgcolor'           : color === 'white'
						? 'divider'
						: variant === 'text' && ( backgroundColorMap[ color ] || color || '#000000' ),
					'.buttonText': {
						transform : color !== 'white' && ( variant !== 'text' && 'translateX(-10px)' ),
						color     : '#000000',
						transition: '0.5s',
					},
					'.arrowRight': color !== 'white' && {
						transform: variant !== 'text' && 'translateY(0px)',
						opacity  : 1,
					},
				},
				'.buttonContent': {
					textDecoration: 'none !important',
					position      : 'relative',
					transition    : '0.5s',
					display       : 'flex',
					alignItems    : 'center',
					justifyContent: 'center',
					width         : '100%',
					height        : '100%',
				},
				'.arrowRight': {
					fontSize     : 25,
					color        : backgroundColorMap[ endIconColor ] || endIconColor || '#000000',
					fontWeight   : 'bold',
					position     : 'absolute',
					bottom       : 0,
					right        : -20,
					top          : 0,
					transform    : variant !== 'text' && 'translateX(10px)',
					transition   : '0.5s',
					opacity      : 0,
					pointerEvents: 'none',
				},
				...props?.sx,
			}}>
			<Box
				component={!onClick ? PageLinkComponent : undefined}
				href={href || '#'}
				target={target}
				className='buttonContent'
				onClick={onClick}>
				<Typography className='buttonText'>
					{children}
				</Typography>
				{endIcon || <ArrowRightAltRoundedIcon className='arrowRight'/>}
			</Box>
		</Box>
	);
}
