import StaticPageLayout from '@/pages/static/_layout';
import { CompleteSplash } from '@/providers/splash';
import { Box, Container, Typography } from '@mui/material';
import lottie from 'lottie-web';
import { useEffect } from 'react';

export default function Error404() {
	useEffect( () => {
		lottie.loadAnimation( {
			container: document.querySelector( '#LottieFour0Four' ) as HTMLDivElement,
			renderer : 'svg',
			loop     : true,
			autoplay : true,
			path     : 'https://www.invoiss.com/lottie/FourOfour.json',
		} );
	}, [] );
	
	return (
		<StaticPageLayout navbarContainerProps={{ maxWidth: 'xl' }} footerContainerProps={{ maxWidth: 'xl' }}>
			<Container
				disableGutters
				maxWidth='xl'
				className='borderedContainer'
				sx={{ height: 'calc(100vh - (64px + 403px))' }}>
				<CompleteSplash/>
				<Box sx={{ textAlign: 'center', height: '100%', pt: 5 }}>
					<Box sx={{ display: 'flex', justifyContent: 'center' }}>
						<Box key='lottie' id='LottieFour0Four' sx={{ width: 600, height: 250 }}/>
					</Box>
					<Typography variant='h1' fontWeight={500}>
						We are sorry, the page you requested cannot be found.
					</Typography>
					<Typography variant='h3' fontWeight='normal'>
						The URL may be misspelled or the page you're looking for is no longer available
					</Typography>
				</Box>
			</Container>
		</StaticPageLayout>
	);
}
