import PageLinkComponent from '@/components/page/linkComponent';
import NavbarLink from '@/pages/static/navbar/navbarLink';
import { NavbarLinkProps } from '@/pages/static/routes';
import { KeyboardArrowDownRounded as KeyboardArrowDownRoundedIcon } from '@mui/icons-material';
import {
	Box,
	Grow,
	ListItemIcon,
	ListItemText,
	MenuItem,
	MenuList,
	Stack,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material';
import { useState } from 'react';

export default function NavbarWebMenu( { route }: { route: NavbarLinkProps } ) {
	const theme = useTheme();
	const isDarkMode = theme.palette.mode === 'dark';
	
	// State to control the tooltip's open status
	const [ open, setOpen ] = useState( false );
	
	// Handle open and close events
	const handleOpen = () => setOpen( true );
	const handleClose = () => setOpen( false );
	
	if ( !route.routes ) return (
		<NavbarLink
			href={route.href}
			sx={{
				'transition'  : '.3s',
				'p'           : .8,
				'borderRadius': 2,
				':hover'      : {
					bgcolor: 'divider',
					p      : {
						color: 'text.secondary',
					},
					cursor: 'pointer',
				},
			}}>
			{route.name}
		</NavbarLink>
	);
	
	// Divide the routes into chunks of 3
	const columns = route.routes.reduce( ( acc: NavbarLinkProps[][], link, index ) => {
		const columnIndex = Math.floor( index / 3 );
		if ( !acc[ columnIndex ] ) acc[ columnIndex ] = [];
		acc[ columnIndex ].push( link );
		return acc;
	}, [] );
	
	return (
		<Tooltip
			TransitionComponent={Grow}
			placement='bottom-start'
			open={open}
			componentsProps={{
				tooltip: {
					sx: {
						'maxWidth'           : 'none',
						'bgcolor'            : isDarkMode ? '#000000' : undefined,
						'borderColor'        : 'divider',
						'boxShadow'          : 25,
						'p'                  : '0px !important',
						'borderRadius'       : 2,
						'& .MuiTooltip-arrow': {
							':before': {
								border: `1px solid ${theme.palette.divider}`,
							},
							'color': isDarkMode ? '#000000' : undefined,
						},
					},
				},
			}}
			title={(
				<Stack
					direction='row'
					sx={{
						'& .MuiListItemIcon-root': {
							alignSelf   : 'start',
							mt          : 0.5,
							minWidth    : 40,
							p           : 1,
							mr          : 2,
							bgcolor     : 'transparent',
							border      : 1,
							borderColor : 'divider',
							borderRadius: 2,
							svg         : { fontSize: '2rem !important', color: 'text.primary' },
						},
					}}>
					<Stack
						spacing={1}
						sx={{
							p: 2,
							// width: 600
						}}>
						<Typography color='text.secondary'>
							FEATURES
						</Typography>
						<MenuList disablePadding sx={{ p: 0, display: 'flex' }}>
							{columns.map( ( column, columnIndex ) => (
								<Box
									key={columnIndex}
									sx={{
										display      : 'flex',
										flexDirection: 'column',
										gap          : 1,
									}}>
									{column.map( ( link, index ) => (
										<MenuItem
											key={index}
											component={PageLinkComponent}
											sx={{ maxWidth: 300 }}
											href={link.href}>
											<ListItemIcon>
												{link.icon as any}
											</ListItemIcon>
											<ListItemText
												primary={link.name}
												secondary={link.description}
												primaryTypographyProps={{
													sx: {
														fontWeight: 500,
														fontSize  : '16px !important',
													},
												}}
											/>
										</MenuItem>
									) )}
								</Box>
							) )}
						</MenuList>
					</Stack>
					{/*<Divider flexItem orientation='vertical' variant='middle'/>*/}
					{/*<Stack spacing={2} sx={{ p: 2, width: 300 }}>*/}
					{/*	<Typography color='text.secondary'>*/}
					{/*		ARTICLES*/}
					{/*	</Typography>*/}
					{/*	<MenuItem*/}
					{/*		component={PageLinkComponent}*/}
					{/*		sx={{ maxWidth: 300 }}*/}
					{/*		href='/articles'>*/}
					{/*		<ListItemIcon>*/}
					{/*			<NewspaperRoundedIcon/>*/}
					{/*		</ListItemIcon>*/}
					{/*		<ListItemText*/}
					{/*			primary='Articles'*/}
					{/*			secondary='Read articles to learn more about our features'*/}
					{/*			primaryTypographyProps={{*/}
					{/*				sx: {*/}
					{/*					fontWeight: 500,*/}
					{/*					fontSize  : '16px !important',*/}
					{/*				},*/}
					{/*			}}*/}
					{/*		/>*/}
					{/*	</MenuItem>*/}
					{/*	<Box*/}
					{/*		sx={{*/}
					{/*			p           : 1.5,*/}
					{/*			bgcolor     : '#BDDFFF',*/}
					{/*			borderRadius: 2,*/}
					{/*			boxShadow   : 25,*/}
					{/*			width       : '100%',*/}
					{/*			height      : '100%',*/}
					{/*		}}>*/}
					{/*		<StyledImage*/}
					{/*			src='/images/static-images/articles-screenshot.png'*/}
					{/*			sx={{*/}
					{/*				width         : '100%',*/}
					{/*				height        : '100%',*/}
					{/*				borderRadius  : 2,*/}
					{/*				imageRendering: '-webkit-optimize-contrast !important',*/}
					{/*			}}*/}
					{/*		/>*/}
					{/*	</Box>*/}
					{/*</Stack>*/}
				</Stack>
			)}
			onOpen={handleOpen}
			onClose={handleClose}>
			<Stack
				direction='row'
				alignItems='center'
				spacing={.5}
				sx={{
					'transition'  : '.3s',
					'p'           : .8,
					'borderRadius': 2,
					'.routesIcon' : {
						transition: '.3s',
						transform : open ? 'rotate(180deg)' : 'rotate(0deg)',
					},
					':hover': {
						bgcolor: 'divider',
						p      : {
							color: 'text.secondary',
						},
						cursor: 'pointer',
					},
				}}>
				<Typography
					sx={{
						color     : 'text.primary',
						fontWeight: 500,
						transition: '.3s',
						fontSize  : '16px !important',
					}}>
					{route.name}
				</Typography>
				<KeyboardArrowDownRoundedIcon className='routesIcon'/>
			</Stack>
		</Tooltip>
	);
}
